<template>
    <el-form :model="addForm" :rules="formRules" ref="addForm" label-width="190px" class="demo-ruleForm" :hide-required-asterisk="true">
        <el-form-item label="考试名称" prop="name">
            <el-input v-model.trim="addForm.name" placeholder="2-18个字" maxlength="18" show-word-limit style="width: 500px"></el-input>
        </el-form-item>
        <el-form-item label="考试等级" prop="level">
            <el-select v-model="addForm.level" placeholder="请选择考试等级" :disabled="examStatus" style="width: 500px" @change="changeLevel">
                <el-option label="四级电子商务员" :value="1"></el-option>
                <el-option label="三级助理电子商务师" :value="2"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="开始时间" prop="begin_time">
            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"
                            placeholder="请选择开始时间" v-model="addForm.begin_time" style="width: 500px;"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="end_time">
            <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" @change="selectOverTime"
                            placeholder="请选择结束时间" v-model="addForm.end_time" style="width: 500px;"></el-date-picker>
        </el-form-item>
        <el-form-item label="选择队伍" prop="team_id">
            <el-select v-model="addForm.team_id" placeholder="班级名称" :disabled="examStatus" clearable style="width: 500px">
                <el-option v-for="item in teamList" :label="item.name" :value="item.id" :key="`teamList_${item.id}`"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="考试类型" prop="type">
            <el-radio-group v-model="addForm.type" @change="changeType" :disabled="examStatus">
                <el-radio :label="2">模拟考试</el-radio>
                <el-radio :label="1">正式考试</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="选择试题" prop="mode">
            <el-radio-group v-model="addForm.mode" @change="changeMode" :disabled="examStatus">
                <el-radio :label="1" style="width: 80px">试卷</el-radio>
                <el-radio :label="2">随机试题</el-radio>
            </el-radio-group>
            <el-button @click="getRandomList" type="primary" v-if="addForm.mode === 2" size="medium" :disabled="examStatus" style="margin-left: 20px">重新选择</el-button>
        </el-form-item>
        <template v-if="addForm.mode === 1">
            <el-form-item label="试卷模板" prop="paper_id">
                <el-select v-model="addForm.paper_id" placeholder="试卷名称" clearable style="width: 500px" :disabled="examPaperList.length === 0 || examStatus">
                    <el-option v-for="item in examPaperList" :label="item.name" :value="item.id" :key="`examPaperList_${item.id}`"></el-option>
                </el-select>
            </el-form-item>
        </template>
        <template v-else>
            <el-form-item label="理论题" prop="theory_id">
                <el-select v-model="addForm.theory_id" placeholder="试卷名称" clearable style="width: 500px" :disabled="theoryList.length === 0 || examStatus">
                    <el-option v-for="item in theoryList" :label="item.name" :value="item.id" :key="`theoryList_${item.id}`"></el-option>
                </el-select>
                <el-input v-model.trim="addForm.theory_score" disabled style="width: 100px; margin-left: 10px"></el-input>
                <span class="prompt-text">分</span>
            </el-form-item>
<!--            四级实操题-->
            <template v-if="addForm.level === 1">
                <el-form-item label="商品信息采集与处理" prop="goods_info_collect_score">
                    <el-input v-model="addForm.goods_info_collect_name" placeholder="试题名称" style="width: 500px" disabled></el-input>
                    <el-input-number class="number-input" v-model="addForm.goods_info_collect_score" :precision="1" :disabled="examStatus"
                                     :controls="false" :min="0.1" :max="100.0" style="width: 100px; margin-left: 10px"></el-input-number>
<!--                    <el-input v-model.trim="addForm.goods_info_collect_score" disabled style="width: 100px; margin-left: 10px"></el-input>-->
                    <span class="prompt-text">分</span>
                </el-form-item>
                <el-form-item label="网店装修" prop="shop_decoration_score">
                    <el-input v-model="addForm.shop_decoration_name" placeholder="试题名称" style="width: 500px" disabled></el-input>
                    <el-input-number class="number-input" v-model="addForm.shop_decoration_score" :precision="1" :disabled="examStatus"
                                     :controls="false" :min="0.1" :max="100.0" style="width: 100px; margin-left: 10px"></el-input-number>
<!--                    <el-input v-model.trim="addForm.shop_decoration_score" disabled style="width: 100px; margin-left: 10px"></el-input>-->
                    <span class="prompt-text">分</span>
                </el-form-item>
                <el-form-item label="网店管理" prop="shop_management_score">
                    <el-input v-model="addForm.shop_management_name" placeholder="试题名称" style="width: 500px" disabled></el-input>
                    <el-input-number class="number-input" v-model="addForm.shop_management_score" :precision="1" :disabled="examStatus"
                                     :controls="false" :min="0.1" :max="100.0" style="width: 100px; margin-left: 10px"></el-input-number>
<!--                    <el-input v-model.trim="addForm.shop_management_score" disabled style="width: 100px; margin-left: 10px"></el-input>-->
                    <span class="prompt-text">分</span>
                </el-form-item>
                <el-form-item label="网络推广" prop="network_promotion_score">
                    <el-input v-model="addForm.network_promotion_name" placeholder="试题名称" style="width: 500px" disabled></el-input>
                    <el-input-number class="number-input" v-model="addForm.network_promotion_score" :precision="1" :disabled="examStatus"
                                     :controls="false" :min="0.1" :max="100.0" style="width: 100px; margin-left: 10px"></el-input-number>
<!--                    <el-input v-model.trim="addForm.network_promotion_score" disabled style="width: 100px; margin-left: 10px"></el-input>-->
                    <span class="prompt-text">分</span>
                </el-form-item>
                <el-form-item label="网店客户服务" prop="store_customer_service_score">
                    <el-input v-model="addForm.store_customer_service_name" placeholder="试题名称" style="width: 500px" disabled></el-input>
                    <el-input-number class="number-input" v-model="addForm.store_customer_service_score" :precision="1" :disabled="examStatus"
                                     :controls="false" :min="0.1" :max="100.0" style="width: 100px; margin-left: 10px"></el-input-number>
<!--                    <el-input v-model.trim="addForm.store_customer_service_score" disabled style="width: 100px; margin-left: 10px"></el-input>-->
                    <span class="prompt-text">分</span>
                </el-form-item>
                <el-form-item label="电子商务数据收集与清洗" prop="data_capture_clean_score">
                    <el-input v-model="addForm.data_capture_clean_name" placeholder="试题名称" style="width: 500px" disabled></el-input>
                    <el-input-number class="number-input" v-model="addForm.data_capture_clean_score" :precision="1" :disabled="examStatus"
                                     :controls="false" :min="0.1" :max="100.0" style="width: 100px; margin-left: 10px"></el-input-number>
<!--                    <el-input v-model.trim="addForm.data_capture_clean_score" disabled style="width: 100px; margin-left: 10px"></el-input>-->
                    <span class="prompt-text">分</span>
                </el-form-item>
            </template>
<!--            三级实操题-->
            <template v-else-if="addForm.level === 2">
                <el-form-item label="网店视觉设计" prop="store_visual_design_score">
                    <el-input v-model="addForm.store_visual_design_name" placeholder="试题名称" style="width: 500px" disabled></el-input>
                    <el-input-number class="number-input" v-model="addForm.store_visual_design_score" :precision="1" :disabled="examStatus"
                                     :controls="false" :min="0.1" :max="100.0" style="width: 100px; margin-left: 10px"></el-input-number>
<!--                    <el-input v-model.trim="addForm.store_visual_design_score" disabled style="width: 100px; margin-left: 10px"></el-input>-->
                    <span class="prompt-text">分</span>
                </el-form-item>
                <el-form-item label="网络营销" prop="network_marketing_score">
                    <el-input v-model="addForm.network_marketing_name" placeholder="试题名称" style="width: 500px" disabled></el-input>
                    <el-input-number class="number-input" v-model="addForm.network_marketing_score" :precision="1" :disabled="examStatus"
                                     :controls="false" :min="0.1" :max="100.0" style="width: 100px; margin-left: 10px"></el-input-number>
<!--                    <el-input v-model.trim="addForm.network_marketing_score" disabled style="width: 100px; margin-left: 10px"></el-input>-->
                    <span class="prompt-text">分</span>
                </el-form-item>
                <el-form-item label="网上交易管理" prop="online_transaction_management_score">
                    <el-input v-model="addForm.online_transaction_management_name" placeholder="试题名称" style="width: 500px" disabled></el-input>
                    <el-input-number class="number-input" v-model="addForm.online_transaction_management_score" :precision="1" :disabled="examStatus"
                                     :controls="false" :min="0.1" :max="100.0" style="width: 100px; margin-left: 10px"></el-input-number>
<!--                    <el-input v-model.trim="addForm.online_transaction_management_score" disabled style="width: 100px; margin-left: 10px"></el-input>-->
                    <span class="prompt-text">分</span>
                </el-form-item>
                <el-form-item label="网络客户服务" prop="store_customer_service_level_three_score">
                    <el-input v-model="addForm.store_customer_service_level_three_name" placeholder="试题名称" style="width: 500px" disabled></el-input>
                    <el-input-number class="number-input" v-model="addForm.store_customer_service_level_three_score" :precision="1" :disabled="examStatus"
                                     :controls="false" :min="0.1" :max="100.0" style="width: 100px; margin-left: 10px"></el-input-number>
<!--                    <el-input v-model.trim="addForm.store_customer_service_level_three_score" disabled style="width: 100px; margin-left: 10px"></el-input>-->
                    <span class="prompt-text">分</span>
                </el-form-item>
                <el-form-item label="电子商务数据分析与应用" prop="data_analysis_application_score">
                    <el-input v-model="addForm.data_analysis_application_name" placeholder="试题名称" style="width: 500px" disabled></el-input>
                    <el-input-number class="number-input" v-model="addForm.data_analysis_application_score" :precision="1" :disabled="examStatus"
                                     :controls="false" :min="0.1" :max="100.0" style="width: 100px; margin-left: 10px"></el-input-number>
<!--                    <el-input v-model.trim="addForm.data_analysis_application_score" disabled style="width: 100px; margin-left: 10px"></el-input>-->
                    <span class="prompt-text">分</span>
                </el-form-item>
            </template>
            <el-form-item label="理论题" prop="theory_rate">
                <el-input-number class="number-input" v-model="addForm.theory_rate" placeholder="设置分值比例" :precision="0" :controls="false" :disabled="examStatus"
                                 :min="1" :max="99" style="width: 124px;"></el-input-number>
                <span class="prompt-text">%</span>
            </el-form-item>
            <el-form-item label="实操题" prop="practic_rate">
                <el-input-number class="number-input" v-model="addForm.practic_rate" placeholder="设置分值比例" :precision="0" :controls="false" :disabled="examStatus"
                                 :min="1" :max="99" style="width: 124px;"></el-input-number>
                <span class="prompt-text">%</span>
            </el-form-item>
        </template>
    </el-form>
</template>

<script>
    import { paperListPaper, examGetTheoryByLevel, examRandPaper, examClass, examGetPaperByLevel } from '@/utils/apis.js'
    export default {
        props: {
            addForm: {
                type: Object,
                default:() => {}
            },
            formRules: {
                type: Object,
                default:() => {}
            },
        },
        data() {
            return {
                teamList: [], // 班级列表
                examPaperList: [], // 试卷列表
                theoryList: [], // 理论题列表
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 3600 * 1000 * 24;
                    }
                },
                examStatus: Boolean(this.$route.query.status) || false
            }
        },
        watch: {
            'addForm.theory_rate'(val) {
                this.addForm.practic_rate = 100 - val
            },
            'addForm.practic_rate'(val) {
                this.addForm.theory_rate = 100 - val
            }
        },
        mounted() {
            this.getTeamList()
            if (this.addForm.level) {
                this.getExamPaperList()
                this.getTheoryList()
            }
        },
        methods: {
            selectOverTime(val){
                if (!this.addForm.begin_time) {
                    this.$message.warning('请先选择开始时间！');
                    this.addForm.end_time = ''
                } else {
                    if (this.addForm.end_time < this.addForm.begin_time) {
                        this.$message.warning('请选择开始时间之后的日期！');
                        this.addForm.end_time = ''
                    }
                }
            },
            // 筛选考试等级
            changeLevel(val) {
                this.examPaperList = []
                this.theoryList = []
                this.getExamPaperList()
                this.getTheoryList()
                if (this.addForm.mode === 2) {
                    this.getRandomList()
                }
                this.$emit('update:addForm', {
                    ...this.addForm,
                    paper_id: null,
                    theory_id: null,
                })
            },
            // 获取班级列表
            getTeamList() {
                examClass().then((res) => {
                    this.teamList = res.data
                })
            },
            // 获取试卷列表
            getExamPaperList() {
                let params = {
                    level: this.addForm.level
                }
              examGetPaperByLevel(params).then((res) => {
                    this.examPaperList = res.data
                })
            },
            // 获取理论题列表
            getTheoryList() {
                let params = {
                    level: this.addForm.level
                }
                examGetTheoryByLevel(params).then((res) => {
                    this.theoryList = res.data
                })
            },
            // 筛选考试类型，模拟考试或者正式考试
            changeType(val) {
                if (val === 1) {
                    this.$emit('update:addForm', {
                        ...this.addForm,
                        give_type: 2,
                        teacher: [],
                    })
                } else {
                    this.$emit('update:addForm', {
                        ...this.addForm,
                        give_type: 1,
                        teacher: [],
                    })
                }
            },
            // 筛选试卷或者随机试题
            changeMode(val) {
                if (val === 2) { // 随机
                    this.getRandomList()
                    this.getTheoryList()
                } else {
                    this.examPaperList = []
                    this.getExamPaperList()
                }
                this.clearFields()
            },
            // 重新选择，随机试题
            getRandomList() {
                let params = {
                    level: this.addForm.level
                }
                examRandPaper(params).then((res) => {
                    if (this.addForm.level === 1) {
                        this.$emit('update:addForm', {
                            ...this.addForm,
                            goods_info_collect: res.data.goods_info_collect, //商品信息采集与处理
                            goods_info_collect_name: res.data.goods_info_collect_name,
                            goods_info_collect_score: res.data.goods_info_collect_score,
                            shop_decoration: res.data.shop_decoration, // 网店装修
                            shop_decoration_name: res.data.shop_decoration_name,
                            shop_decoration_score: res.data.shop_decoration_score,
                            shop_management: res.data.shop_management, // 网店管理
                            shop_management_name: res.data.shop_management_name,
                            shop_management_score: res.data.shop_management_score,
                            network_promotion: res.data.network_promotion, // 网络推广
                            network_promotion_name: res.data.network_promotion_name,
                            network_promotion_score: res.data.network_promotion_score,
                            store_customer_service: res.data.store_customer_service, // 网店客户服务
                            store_customer_service_name: res.data.store_customer_service_name,
                            store_customer_service_score: res.data.store_customer_service_score,
                            data_capture_clean: res.data.data_capture_clean, // 电子商务数据收集与清洗
                            data_capture_clean_name: res.data.data_capture_clean_name,
                            data_capture_clean_score: res.data.data_capture_clean_score,
                        })
                    } else {
                        this.$emit('update:addForm', {
                            ...this.addForm,
                            // 看看有没有返回name的值
                            store_visual_design: res.data.store_visual_design, // 网店视觉设计
                            store_visual_design_name: res.data.store_visual_design_name,
                            store_visual_design_score: res.data.store_visual_design_score,
                            network_marketing: res.data.network_marketing, // 网络营销
                            network_marketing_name: res.data.network_marketing_name,
                            network_marketing_score: res.data.network_marketing_score,
                            online_transaction_management: res.data.online_transaction_management, // 网上交易管理
                            online_transaction_management_name: res.data.online_transaction_management_name,
                            online_transaction_management_score: res.data.online_transaction_management_score,
                            store_customer_service_level_three: res.data.store_customer_service_level_three, // 网店客户服务三级
                            store_customer_service_level_three_name: res.data.store_customer_service_level_three_name,
                            store_customer_service_level_three_score: res.data.store_customer_service_level_three_score,
                            data_analysis_application: res.data.data_analysis_application, // 电子商务数据分析与应用
                            data_analysis_application_name: res.data.data_analysis_application_name,
                            data_analysis_application_score: res.data.data_analysis_application_score,
                        })
                    }
                }).catch((err) => {})
            },
            clearFields() {
                for (let key in this.addForm) {
                    if (!this.addForm[key]) {
                        // 切换，清除开启提交做的验证
                        this.$refs['addForm'].clearValidate([key])
                    }
                }
                // this.$refs['addForm'].resetFields();
            },
        }
    }
</script>

<style scoped lang="scss">
    .demo-ruleForm {
        margin: 20px 0 0 60px;
        ::v-deep .el-form-item__label {
            font-size: 16px;
            color: #333;
        }
    }
    .prompt-text {
        font-size: 16px;
        margin-left: 10px;
    }
    .number-input {
        ::v-deep .el-input__inner {
            text-align: left;
        }
    }
</style>