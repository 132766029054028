<template>
    <div class="stepTwo-container">
        <div class="grading-type">
            <span class="name">评分类型：</span>
            <div class="text">
                <el-radio class="customRadio" v-if="addForm.type === 2" v-model="addForm.give_type"
                          :label="1" :disabled="examStatus">将学生随机分配给教师，最终分数为该教师评分的分数</el-radio>
                <el-radio-group v-else class="customRadioGroup" v-model="addForm.give_type" :disabled="examStatus">
                    <el-radio :label="2">将学生随机分配给评委，最终分数为该评委评分的分数</el-radio>
                    <el-radio :label="3">选择评分评委同时对学生评分，取平均分做该学生的分数</el-radio>
                    <el-radio :label="4">选择评分评委同时对学生评分，去掉最高分和最低分，取剩下分数的平均分做该学生的分数</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="stepTwo-box">
            <div class="item-box left-box">
                <div class="box-top">选择评分教师</div>
                <el-scrollbar class="box-content">
                    <div class="person-list">
                        <div class="person-item" :class="[addForm.teacher.find(item1 => item1.id === item.id) ? 'current' : '', examStatus ? 'noClick' : '']"
                             v-for="(item, index) in addForm.teacherList" :key="`teacherList_${item.id}`"
                             @click="addTeacher(item)">
                            {{item.nickname}}</div>
                    </div>
                </el-scrollbar>
            </div>
            <div class="item-box right-box">
                <div class="box-top">已选择{{addForm.teacher.length}}个教师</div>
                <el-scrollbar class="box-content">
                    <div class="person-list" v-if="Array.isArray(addForm.teacher)">
                        <div class="person-item" :class="examStatus ? 'noClick' : ''"
                             v-for="(item, index) in addForm.teacher" :key="`chooseList_${item.id}`">
                            {{item.nickname}}<i class="iconfont" @click="delTeacher(index)">&#xe668;</i>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
    import { getTeacherList, getJudgeList } from '@/utils/apis.js'
    export default {
        props: {
            addForm: {
                type: Object,
                default:() => {}
            },
        },
        data() {
            return {
                examStatus: Boolean(this.$route.query.status) || false
            }
        },
        mounted() {
            if (this.addForm.type === 1) {
                this.getJudgesList()
            } else {
                this.getTeachersList()
            }
        },
        methods: {
            // 获取教师列表
            getTeachersList() {
                getTeacherList().then((res) => {
                    // this.addForm.teacherList = res.data
                    this.$set(this.addForm, 'teacherList', res.data)
                    if (this.addForm.id && res.data.length > 0) {
                        if (typeof this.addForm.teacher === 'string') {
                            let chooseTmp = this.addForm.teacher.split('#').map(item => {
                                return Number(item)
                            })
                            this.addForm.teacher = this.addForm.teacherList.filter(item => {
                                return chooseTmp.includes(item.id)
                            })
                        }
                    }
                })
            },
            // 获取评委列表
            getJudgesList() {
                getJudgeList().then((res) => {
                    // this.addForm.teacherList = res.data
                    this.$set(this.addForm, 'teacherList', res.data)
                    if (this.addForm.id && res.data.length > 0) {
                        if (typeof this.addForm.teacher === 'string') {
                            let chooseTmp = this.addForm.teacher.split('#').map(item => {
                                return Number(item)
                            })
                            this.addForm.teacher = this.addForm.teacherList.filter(item => {
                                return chooseTmp.includes(item.id)
                            })
                        }
                    }
                })
            },
            // 添加教师
            addTeacher(item) {
                let tmp = this.addForm.teacher.find(item1 => item1.id === item.id)
                if (tmp) {
                    return this.$message.warning('已选过该评委')
                } else {
                    this.addForm.teacher.push(item)
                }
            },
            // 删除教师
            delTeacher(index) {
                this.addForm.teacher.splice(index, 1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .stepTwo-container {
        margin: 20px 160px 0;
        /*height: calc(100vh - 383px);*/
        height: calc(100vh - 423px);
        display: flex;
        flex-direction: column;
    }
    .grading-type {
        display: flex;
        flex-wrap: wrap;
        .name {
            line-height: 17px;
        }
        .text {
            width: 1%;
            flex: 1;
            margin-top: -10px;
        }
    }
    .stepTwo-box {
        margin-top: 20px;
        display: flex;
        height: 1%;
        flex: 1;
        .item-box {
            height: 100%;
            display: flex;
            flex-direction: column;
            .box-top {
                line-height: 60px;
                background: #E7F6EF;
                padding-left: 30px;
            }
            .box-content {
                border: 1px solid #eee;
                border-top: 0;
                height: 1%;
                flex: 1;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
            }
        }
        .left-box {
            width: 60%;
        }
        .right-box {
            margin-left: 30px;
            width: 1%;
            flex: 1;
        }
    }
    .person-list {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 30px;
        padding-right: 30px;
        .person-item {
            height: 38px;
            line-height: 38px;
            background: #fff;
            border: 1px solid #DCDCDC;
            padding: 0 8px;
            margin-left: 30px;
            margin-top: 30px;
            cursor: pointer;
            position: relative;
            transition: all .3s;
            &.current {
                border-color: #2DC079;
                background: #E7F6EF;
            }
            &.noClick {
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.6;
            }
            &:hover {
                color: #2DC079;
            }
            .iconfont {
                position: absolute;
                font-size: 12px;
                line-height: 1;
                color: #707070;
                top: 0;
                right: -16px;
                transition: all .3s;
                &:hover {
                    color: red;
                }
            }
        }
    }
    .customRadio {
        color: #666;
        font-weight: normal;
        margin-top: 10px;
        ::v-deep {
            .el-radio__input.is-checked + .el-radio__label {
                color: #666;
            }
            .el-radio__input.is-disabled+span.el-radio__label {
                color: #C0C4CC!important;
            }
        }
    }
    .customRadioGroup {
        .el-radio {
            color: #666;
            margin-top: 10px;
        }
        ::v-deep {
            .el-checkbox-button__inner, .el-radio {
                font-weight: normal;
            }
            .el-radio__input.is-checked + .el-radio__label {
                color: #666;
            }
        }
    }
</style>