<template>
    <el-scrollbar class="main-wrapper">
        <el-breadcrumb separator=">" class="genera-breadcrumb" style="margin-left: 20px">
            <el-breadcrumb-item>考试管理</el-breadcrumb-item>
            <el-breadcrumb-item>创建考试</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="steps-wrapper">
            <div class="step-item" :class="{current: [1, 2, 3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">创建考试</div>
            </div>
            <div class="step-item" :class="{current: [2, 3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">选择评分教师</div>
            </div>
            <div class="step-item" :class="{current: [3].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">3</div>
                <div class="step-title">创建成功</div>
            </div>
        </div>

        <ExamStepOne v-if="stepActive === 1" ref="examStepOne" :addForm.sync="addForm" :formRules.sync="formRules" />
        <ExamStepTwo v-if="stepActive === 2" ref="examStepTwo" :addForm.sync="addForm" />
        <div class="creating-success" v-if="stepActive === 3">
            <img :src="require('../../../assets/images/admin/success.png')" alt="">
            <div class="text">保存成功</div>
        </div>
        <div class="bottom-btn">
            <el-button @click="prevBtn(stepActive)" v-if="stepActive === 1 || stepActive === 2">返回</el-button>
            <el-button @click="prevBtn(stepActive)" type="primary" v-if="stepActive === 3">返回列表</el-button>
            <el-button @click="nextBtn(stepActive)" type="primary" v-if="stepActive === 1">下一步</el-button>
            <el-button @click="nextBtn(stepActive)" type="primary" v-if="stepActive === 2">下一步</el-button>
        </div>
    </el-scrollbar>
</template>

<script>
    import { examSave, getExam } from '@/utils/apis.js'
    import ExamStepOne from '@/components/teacher/examCenter/createExam/ExamStepOne'
    import ExamStepTwo from '@/components/teacher/examCenter/createExam/ExamStepTwo'
    export default {
        components: {
            ExamStepOne,
            ExamStepTwo
        },
        data() {
            return {
                //步骤
                stepActive: 1,
                addForm: {
                    id: Number(this.$route.query.id) || null,
                    name: '', // 考试名称
                    level: null, // 考试等级 1:四级 2：三级
                    begin_time: null, // 开始时间
                    end_time: null, // 结束时间
                    team_id: null, // 队伍id
                    type: 1, // 考试类型 正式考试1 模拟考试2
                    mode: 1, // 选择试题 试卷1 随机试题2
                    paper_id: null, // 试卷数据
                    give_type: 2, // 评分类型
                    teacher: [], // 评分教师（评委）

                    theory_id: null, // 理论题试卷ID
                    theory_score: 100, // 理论题试卷ID
                    // S 四级
                    goods_info_collect: null, //商品信息采集与处理
                    goods_info_collect_name: '',
                    // goods_info_collect_score: null,
                    goods_info_collect_score: void 0,
                    shop_decoration: null, // 网店装修
                    shop_decoration_name: '',
                    // shop_decoration_score: null,
                    shop_decoration_score: void 0,
                    shop_management: null, // 网店管理
                    shop_management_name: '',
                    // shop_management_score: null,
                    shop_management_score: void 0,
                    network_promotion: null, // 网络推广
                    network_promotion_name: '',
                    // network_promotion_score: null,
                    network_promotion_score: void 0,
                    store_customer_service: null, // 网店客户服务
                    store_customer_service_name: '',
                    // store_customer_service_score: null,
                    store_customer_service_score: void 0,
                    data_capture_clean: null, // 电子商务数据收集与清洗
                    data_capture_clean_name: '',
                    // data_capture_clean_score: null,
                    data_capture_clean_score: void 0,
                    // E 四级

                    // S 三级
                    store_visual_design: '', // 网店视觉设计
                    store_visual_design_name: '',
                    // store_visual_design_score: null,
                    store_visual_design_score: void 0,
                    network_marketing: '', // 网络营销
                    network_marketing_name: '',
                    // network_marketing_score: null,
                    network_marketing_score: void 0,
                    online_transaction_management: '', // 网上交易管理
                    online_transaction_management_name: '',
                    // online_transaction_management_score: null,
                    online_transaction_management_score: void 0,
                    store_customer_service_level_three: '', // 网店客户服务三级
                    store_customer_service_level_three_name: '',
                    // store_customer_service_level_three_score: null,
                    store_customer_service_level_three_score: void 0,
                    data_analysis_application: '', // 电子商务数据分析与应用
                    data_analysis_application_name: '',
                    // data_analysis_application_score: null,
                    data_analysis_application_score: void 0,
                    // E 三级
                    theory_rate: void 0, // 理论题分值比例
                    practic_rate: void 0, // 实操题分值比例

                    teacherList: [],
                    judgeList: [],
                },
                formRules: {
                    name: [
                        { required: true, message: '请输入考试名称', trigger: 'blur' },
                        { min: 2, max: 18, message: '长度在 2 到 18 个字', trigger: 'blur' }
                    ],
                    level: { required: true, message: '请选择考试等级', trigger: 'change' },
                    begin_time: [
                        { required: true, message: '请选择开始时间', trigger: 'blur' }
                    ],
                    end_time: [
                        { required: true, message: '请选择结束时间', trigger: 'blur' }
                    ],
                    team_id: { required: true, message: '请选择队伍', trigger: 'change' },
                    paper_id: { required: true, message: '请选择试卷模板', trigger: 'change' },
                    theory_id: { required: true, message: '请选择理论题', trigger: 'change' },

                    goods_info_collect_name: { required: true, message: '请选择商品信息采集与处理', trigger: 'blur' },
                    shop_decoration_name: { required: true, message: '请选择网店装修', trigger: 'blur' },
                    shop_management_name: { required: true, message: '请选择网店管理', trigger: 'blur' },
                    network_promotion_name: { required: true, message: '请选择网络推广', trigger: 'blur' },
                    store_customer_service_name: { required: true, message: '请选择网店客户服务', trigger: 'blur' },
                    data_capture_clean_name: { required: true, message: '请选择电子商务数据收集与清洗', trigger: 'blur' },

                    store_visual_design_name: { required: true, message: '请选择网店视觉设计', trigger: 'blur' },
                    network_marketing_name: { required: true, message: '请选择网络营销', trigger: 'blur' },
                    online_transaction_management_name: { required: true, message: '请选择网上交易管理', trigger: 'blur' },
                    store_customer_service_level_three_name: { required: true, message: '请选择网店客户服务', trigger: 'blur' },
                    data_analysis_application_name: { required: true, message: '请选择电子商务数据分析与应用', trigger: 'blur' },

                    goods_info_collect_score: { required: true, message: '请输入分数', trigger: 'blur' },
                    shop_decoration_score: { required: true, message: '请输入分数', trigger: 'blur' },
                    shop_management_score: { required: true, message: '请输入分数', trigger: 'blur' },
                    network_promotion_score: { required: true, message: '请输入分数', trigger: 'blur' },
                    store_customer_service_score: { required: true, message: '请输入分数', trigger: 'blur' },
                    data_capture_clean_score: { required: true, message: '请输入分数', trigger: 'blur' },

                    store_visual_design_score: { required: true, message: '请输入分数', trigger: 'blur' },
                    network_marketing_score: { required: true, message: '请输入分数', trigger: 'blur' },
                    online_transaction_management_score: { required: true, message: '请输入分数', trigger: 'blur' },
                    store_customer_service_level_three_score: { required: true, message: '请输入分数', trigger: 'blur' },
                    data_analysis_application_score: { required: true, message: '请输入分数', trigger: 'blur' },

                    theory_rate: [
                        { required: true, message: '请设置理论题分值比例', trigger: 'blur' }
                    ],
                    practic_rate: [
                        { required: true, message: '请设置实操题分值比例', trigger: 'blur' }
                    ],
                },
                examStatus: Boolean(this.$route.query.status) || false
            }
        },
        mounted() {
            if (this.addForm.id) {
                this.getExamInfo()
            }
        },
        methods: {
            getExamInfo() {
                let params = {
                    id: this.addForm.id
                }
                getExam(params).then((res) => {
                    this.addForm = res.data
                    this.$nextTick(() => {
                        if (this.addForm.level && this.addForm.mode === 1) {
                            this.$refs.examStepOne.getExamPaperList()
                        } else if (this.addForm.level && this.addForm.mode === 2) {
                            this.$refs.examStepOne.getTheoryList()
                        }
                    })
                })
            },
            // 上一步按钮
            prevBtn(index) {
                if (index === 1) {
                    this.$router.go(-1)
                } else if (index === 2) {
                    this.stepActive = 1
                } else if (index === 3) {
                    this.$router.push('/examCenter/examManagement')
                }
            },
            // 下一步按钮
            nextBtn(index) {
                // 第一步的下一步
                if (index === 1) {
                    this.$refs.examStepOne.$refs.addForm.validate((valid) => {
                        if (valid) {
                            if (this.addForm.mode === 2) {
                                let total = 0
                                // 四级实操题总分加起来100
                                if (this.addForm.level === 1) {
                                    total = this.addForm.goods_info_collect_score +
                                        this.addForm.shop_decoration_score +
                                        this.addForm.shop_management_score +
                                        this.addForm.network_promotion_score +
                                        this.addForm.store_customer_service_score + this.addForm.data_capture_clean_score
                                } else {
                                    // 三级实操题总分加起来100
                                    total = this.addForm.store_visual_design_score +
                                        this.addForm.network_marketing_score +
                                        this.addForm.online_transaction_management_score +
                                        this.addForm.store_customer_service_level_three_score + this.addForm.data_analysis_application_score
                                }
                                if (total !== 100) {
                                    return this.$message.warning('实操题总分应为100')
                                }
                            }
                            this.stepActive = 2
                        } else {
                            return false;
                        }
                    });
                    // 第二步的下一步
                } else if (index === 2) {
                    if (this.addForm.teacher.length === 0) {
                        return this.$message.warning('请先选择教师')
                    }
                    let teacherTmp = this.addForm.teacher.map(item => {
                        return item.id
                    })

                    let formData = new FormData()
                    formData.append('name', this.addForm.name)
                    formData.append('type', this.addForm.type)
                    formData.append('level', this.addForm.level)
                    formData.append('begin_time', this.addForm.begin_time)
                    formData.append('end_time', this.addForm.end_time)
                    formData.append('team_id', this.addForm.team_id)
                    formData.append('mode', this.addForm.mode)
                    formData.append('give_type', this.addForm.give_type)
                    formData.append('teacher', teacherTmp.join('#'))
                    if (this.addForm.mode === 1) {
                        formData.append('paper_id', this.addForm.paper_id)
                    } else {
                        formData.append('theory_id', this.addForm.theory_id)
                        formData.append('theory_rate', this.addForm.theory_rate)
                        formData.append('practic_rate', this.addForm.practic_rate)
                        if (this.addForm.level === 1) {
                            formData.append('goods_info_collect', this.addForm.goods_info_collect)
                            formData.append('shop_decoration', this.addForm.shop_decoration)
                            formData.append('shop_management', this.addForm.shop_management)
                            formData.append('network_promotion', this.addForm.network_promotion)
                            formData.append('store_customer_service', this.addForm.store_customer_service)
                            formData.append('data_capture_clean', this.addForm.data_capture_clean)

                            formData.append('goods_info_collect_score', this.addForm.goods_info_collect_score)
                            formData.append('shop_decoration_score', this.addForm.shop_decoration_score)
                            formData.append('shop_management_score', this.addForm.shop_management_score)
                            formData.append('network_promotion_score', this.addForm.network_promotion_score)
                            formData.append('store_customer_service_score', this.addForm.store_customer_service_score)
                            formData.append('data_capture_clean_score', this.addForm.data_capture_clean_score)
                        } else {
                            formData.append('store_visual_design', this.addForm.store_visual_design)
                            formData.append('network_marketing', this.addForm.network_marketing)
                            formData.append('online_transaction_management', this.addForm.online_transaction_management)
                            formData.append('store_customer_service_level_three', this.addForm.store_customer_service_level_three)
                            formData.append('data_analysis_application', this.addForm.data_analysis_application)

                            formData.append('store_visual_design_score', this.addForm.store_visual_design_score)
                            formData.append('network_marketing_score', this.addForm.network_marketing_score)
                            formData.append('online_transaction_management_score', this.addForm.online_transaction_management_score)
                            formData.append('store_customer_service_level_three_score', this.addForm.store_customer_service_level_three_score)
                            formData.append('data_analysis_application_score', this.addForm.data_analysis_application_score)
                        }
                    }
                    if (this.addForm.id) {
                        formData.append('id', this.addForm.id)
                    }
                    examSave(formData).then((res) => {
                        this.$message.success(res.msg)
                        this.stepActive = 3
                    }).catch((err) => {})
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .bottom-btn {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 50px;
    }
    .creating-success {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 120px;
        img {
            width: 240px;
        }
        .text {
            color: #333333;
            font-size: 24px;
            line-height: 1;
            margin: 32px 0 36px;
        }
    }
    /*.demo-ruleForm {*/
    /*    ::v-deep .el-form-item__label {*/
    /*        font-size: 16px;*/
    /*        color: #333;*/
    /*    }*/
    /*}*/
    /*.prompt-text {*/
    /*    font-size: 16px;*/
    /*    margin-left: 10px;*/
    /*}*/
    /*.number-input {*/
    /*    ::v-deep .el-input__inner {*/
    /*        text-align: left;*/
    /*    }*/
    /*}*/
</style>